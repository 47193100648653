.notification {
    width: 100%;
    height: 3rem;
    background-color: #365072;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 10%;
    align-items: center;
    color: rgb(255, 255, 255);
  }
  
  .notification h2{
    margin: 0;
    color: rgb(255, 255, 255);
  }
  .notification h3{
    margin: 0;
    color: rgb(255, 255, 255);
  }
  .notification p {

    color: rgb(255, 255, 255);
    font-size: 1rem;
    margin: 0;
  }
  
  .error {
    background-color: #D6B861;
  }
  
  .success {
    background-color: #365072;
  }
  